// Importing scripts
import * as bootstrap from 'bootstrap';
import '@splidejs/splide/css/core';
import './js/csrf';
import './js/cookie-consent';
import './js/breaking-news';
import './js/overview-articles';
import './js/product-area-block';
import './js/product-filter';
import './js/variant-filter';
import './js/filter-select';
import './js/navigation';
import './js/language-fallback';
import './js/scripts';
import 'bs5-lightbox';

import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
// Importing styles (bootstrap + customization)
import './scss/styles.scss';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
window.FilePond = FilePond;
