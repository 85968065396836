// The `afterBlitzInject` event is triggered after each dynamic content tag is injected
document.addEventListener('afterBlitzInject', function (event) {

    if (sessionStorage.getItem("breakingnewsModal") != "hide") {
        const breakingnewsModal = new bootstrap.Modal("#breakingnewsModal", {});
        breakingnewsModal.show();
    }

    const breakingnewsModalEl = document.getElementById('breakingnewsModal')
    breakingnewsModalEl.addEventListener('hidden.bs.modal', event => {
        sessionStorage.setItem('breakingnewsModal', 'hide');
    })

});
